import { TrackingContextProvider } from '@flock/shared-ui'
import { Box, Grid, SxProps, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

type SectionLayoutProps = {
  name: string
  children: React.ReactNode
  backgroundColor?: string
  sidePadding?: string
  verticalPadding?: string
  maxWidth?: string
  fullWidth?: boolean
  columns?: number
  shouldHide?: boolean
  backgroundImage?: string
  backgroundFull?: boolean
  sx?: SxProps<any>
  gridSx?: SxProps<any>
}

const SectionLayout = (props: SectionLayoutProps) => {
  const {
    children,
    backgroundColor,
    name,
    sidePadding,
    verticalPadding,
    maxWidth,
    fullWidth,
    columns,
    shouldHide,
    backgroundImage,
    backgroundFull,
    sx,
    gridSx,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const sidePaddingValues = fullWidth ? '0px' : sidePadding
  const maxWidthValue = fullWidth ? '100vw' : maxWidth

  if (isMobile) {
    return (
      <TrackingContextProvider name={name}>
        <Box
          display={shouldHide ? 'none' : 'flex'}
          justifyContent="center"
          position="relative"
          sx={{
            pt: verticalPadding || '40px',
            pb: verticalPadding || '40px',
            pl: sidePaddingValues || '16px',
            pr: sidePaddingValues || '16px',
            background: `url(${backgroundImage}), lightgray 0px 0px / 100% 100% no-repeat`,
            backgroundSize: 'cover',
            backgroundColor: backgroundColor || 'gray1.main',

            '&:before': backgroundImage
              ? {
                  content: '""',
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                  backgroundColor: `${theme.palette.darkBackground.main}`,
                  opacity: '0.8',
                }
              : null,
          }}
        >
          <Box
            sx={{
              maxWidth: maxWidthValue || '320px',
              width: '100%',
              zIndex: 2,
              ...sx,
            }}
          >
            <Grid container spacing={fullWidth ? 0 : 2} columns={columns || 12}>
              {children}
            </Grid>
          </Box>
        </Box>
      </TrackingContextProvider>
    )
  } else if (isTablet) {
    return (
      <TrackingContextProvider name={name}>
        <Box
          display={shouldHide ? 'none' : 'flex'}
          justifyContent="center"
          position={backgroundImage ? 'relative' : undefined}
          sx={{
            pt: verticalPadding || '80px',
            pb: verticalPadding || '80px',
            pl: sidePaddingValues || '40px',
            pr: sidePaddingValues || '40px',
            background: `url(${backgroundImage}), lightgray 0px 0px / 100% 100% no-repeat`,
            backgroundSize: 'cover',
            backgroundColor: backgroundColor || 'gray1.main',
            '&:before': backgroundImage
              ? {
                  content: '""',
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                  backgroundColor: `${theme.palette.darkBackground.main}`,
                  opacity: '0.8',
                }
              : null,
          }}
        >
          <Box
            sx={{
              maxWidth: maxWidthValue || (isTablet ? '640px' : '1120px'),
              zIndex: 2,
              width: '100%',
              ...sx,
            }}
          >
            <Grid container spacing={fullWidth ? 0 : 4} columns={columns || 12}>
              {props.children}
            </Grid>
          </Box>
        </Box>
      </TrackingContextProvider>
    )
  } else {
    return (
      <TrackingContextProvider name={name}>
        <Box
          display={shouldHide ? 'none' : 'flex'}
          justifyContent="center"
          sx={{
            pt: verticalPadding || '80px',
            pb: verticalPadding || '80px',
            pl: sidePaddingValues || '40px',
            pr: sidePaddingValues || '40px',
            background: `url(${backgroundImage}), lightgray 0px 0px / 100% 100% no-repeat`,
            backgroundPosition: backgroundFull ? '0% 0%' : '100% 50%',
            backgroundSize: backgroundFull ? '100% auto' : '50% auto',
            backgroundRepeat: 'no-repeat',
            backgroundColor: backgroundColor || 'gray1.main',
            ...sx,
          }}
        >
          <Box
            sx={{
              maxWidth: maxWidthValue || (isTablet ? '640px' : '1120px'),
              width: '100%',
            }}
          >
            <Grid
              container
              spacing={fullWidth ? 0 : 4}
              columns={columns || 12}
              sx={gridSx}
            >
              {props.children}
            </Grid>
          </Box>
        </Box>
      </TrackingContextProvider>
    )
  }
}

SectionLayout.defaultProps = {
  shouldHide: false,
}

export default SectionLayout
