import { Box, Grid, Typography, Slider, styled } from '@mui/material'

import React, { useState } from 'react'
import {
  AnnualFlockModelAggregates,
  AnnualSelfManageModelAggregates,
} from '@flock/utils'
import { getFundConfig } from '@flock/shared-ui'

import CashFlowCalculatorTable from '../CashFlowComponents/CashFlowCalculatorTable'

const { theme } = getFundConfig()

const TimeHorizonSlider = styled(Slider)(() => ({
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.sliderBackground.main,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.sliderButton.main,
    height: 10,
    width: 4,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: theme.palette.sliderBackground.main,
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: '1rem',
  },
}))

type RentalPropertyCalculatorCashFlowTableProps = {
  selfManageAggregates: AnnualSelfManageModelAggregates
  flockAggregates: AnnualFlockModelAggregates
}

const RentalPropertyCalculatorCashFlowTable = (
  props: RentalPropertyCalculatorCashFlowTableProps
) => {
  const { selfManageAggregates, flockAggregates } = props

  const selfManageAggregatesCum: AnnualSelfManageModelAggregates = {
    year: [],
    baseRentalRevenue: [],
    vacancy: [],
    turnCost: [],
    effectiveGrossRent: [],
    repairsAndMaintenance: [],
    propertyManagementFee: [],
    propertyTaxes: [],
    insurance: [],
    netOperatingIncome: [],
    capitalReserve: [],
    hoaFees: [],
    utilities: [],
    leasingCommission: [],
    cashFlowAfterCapital: [],
    mortgagePayment: [],
    mortgageInterest: [],
    mortgagePrincipal: [],
    netCashFlow: [],

    mortgageStartingBalance: [],
    mortgageEndingBalance: [],
    ownHomeBeginningEquity: [],
    ownHomeAppreciation: [],
    ownHomeEndingEquity: [],
  }

  const flockAggregatesCum: AnnualFlockModelAggregates = {
    flockCashFlow: [],
    year: [],
    flockBeginningEquity: [],
    flockEndingEquity: [],
    flockAppreciation: [],
  }

  const accumulatorFn = (acc: number, curr: number) => acc + curr

  selfManageAggregates.year.forEach((year: number, index: number) => {
    selfManageAggregatesCum.year.push(index + 1)
    selfManageAggregatesCum.baseRentalRevenue.push(
      selfManageAggregates.baseRentalRevenue
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.vacancy.push(
      selfManageAggregates.vacancy.slice(0, index + 1).reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.turnCost.push(
      selfManageAggregates.turnCost.slice(0, index + 1).reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.effectiveGrossRent.push(
      selfManageAggregates.effectiveGrossRent
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.repairsAndMaintenance.push(
      selfManageAggregates.repairsAndMaintenance
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.propertyManagementFee.push(
      selfManageAggregates.propertyManagementFee
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.propertyTaxes.push(
      selfManageAggregates.propertyTaxes
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.insurance.push(
      selfManageAggregates.insurance
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.netOperatingIncome.push(
      selfManageAggregates.netOperatingIncome
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.capitalReserve.push(
      selfManageAggregates.capitalReserve
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.hoaFees.push(
      selfManageAggregates.hoaFees.slice(0, index + 1).reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.utilities.push(
      selfManageAggregates.utilities
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.leasingCommission.push(
      selfManageAggregates.leasingCommission
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.cashFlowAfterCapital.push(
      selfManageAggregates.cashFlowAfterCapital
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.mortgagePayment.push(
      selfManageAggregates.mortgagePayment
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.mortgageInterest.push(
      selfManageAggregates.mortgageInterest
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.mortgagePrincipal.push(
      selfManageAggregates.mortgagePrincipal
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )
    selfManageAggregatesCum.netCashFlow.push(
      selfManageAggregates.netCashFlow
        .slice(0, index + 1)
        .reduce(accumulatorFn, 0)
    )

    flockAggregatesCum.flockCashFlow.push(
      flockAggregates.flockCashFlow.slice(0, index + 1).reduce(accumulatorFn, 0)
    )
  })

  const marks = selfManageAggregatesCum.year.map((year: number) => ({
    value: Math.floor(year),
    label: `Year ${Math.floor(year).toString()}`,
  }))
  const [cashFlowYear, setCashFlowYear] = useState<number>(marks[0].value)
  const handleCashFlowYearChange = (e: any) => {
    setCashFlowYear(e.target.value || marks[0].value)
  }

  return (
    <Grid item xs={12}>
      <Box
        padding="24px"
        borderRadius="24px"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap="16px"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
        }}
      >
        <Typography variant="h3">Cumulative Cash Flow</Typography>
        <Box sx={{ width: '90%', alignSelf: 'center' }}>
          <TimeHorizonSlider
            value={cashFlowYear}
            valueLabelDisplay="off"
            marks={marks}
            min={marks[0].value}
            max={marks[marks.length - 1].value}
            onChange={handleCashFlowYearChange}
            track={false}
          />
        </Box>
        <CashFlowCalculatorTable
          selfManageAggregates={selfManageAggregatesCum}
          flockAggregates={flockAggregatesCum}
          cashFlowYear={cashFlowYear}
        />
      </Box>
    </Grid>
  )
}

export default RentalPropertyCalculatorCashFlowTable
