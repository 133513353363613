import { formatIntegerDollars } from '@flock/utils'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useFundConfig } from '@flock/shared-ui'

type SellVsFlockComparisonSectionProps = {
  sellingNetValue: number
  contributingNetValue: number
  traditionalSaleText?: string
  rentalPropertyCalc?: boolean
}

const SellVsFlockComparisonSection = (
  props: SellVsFlockComparisonSectionProps
) => {
  const { fundName } = useFundConfig()

  const {
    sellingNetValue,
    contributingNetValue,
    traditionalSaleText,
    rentalPropertyCalc,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const barWidth = rentalPropertyCalc ? 6 : 3

  let leftHeight = '278px'
  let rightHeight = '278px'

  if (contributingNetValue > sellingNetValue) {
    rightHeight = '278px'
    leftHeight = `${(278 * sellingNetValue) / contributingNetValue}px`
  } else {
    leftHeight = '278px'
    rightHeight = `${(278 * contributingNetValue) / sellingNetValue}px`
  }

  const formattedSellingNetValue = formatIntegerDollars(sellingNetValue)
  const formattedFlockNetValue = formatIntegerDollars(contributingNetValue)

  return (
    <>
      {!isTablet && !rentalPropertyCalc && <Grid item xs={3} />}

      <Grid
        item
        xs={6}
        md={barWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="18px"
      >
        <Box height="278px" width="100%" display="flex" alignItems="flex-end">
          <Box
            width="100%"
            minHeight="128px"
            height={leftHeight}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            sx={{
              transition: 'height 1s ease',

              backgroundColor: 'graphSecondary.main',
            }}
          >
            <Typography variant={isMobile ? 'h4' : 'h2'} color="white.main">
              {formattedSellingNetValue}
            </Typography>
            <Typography variant="p2" color="white.main" textAlign="center">
              {traditionalSaleText}
            </Typography>
          </Box>
        </Box>
        <Typography
          align={rentalPropertyCalc && isMobile ? 'center' : undefined}
          variant="p2"
        >
          Traditional Sale
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={barWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="18px"
      >
        <Box height="278px" width="100%" display="flex" alignItems="flex-end">
          <Box
            width="100%"
            minHeight="128px"
            height={rightHeight}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            sx={{
              transition: 'height 1s ease',
              backgroundColor: 'graphPrimary.main',
            }}
          >
            <Typography variant={isMobile ? 'h4' : 'h2'} color="#ffffff">
              {formattedFlockNetValue}
            </Typography>
            <Typography
              variant="p2"
              color="#ffffff"
              align={rentalPropertyCalc ? 'center' : 'right'}
            >
              of {fundName} shares
            </Typography>
          </Box>
        </Box>
        <Typography
          align={rentalPropertyCalc && isMobile ? 'center' : undefined}
          variant="p2"
        >
          Contribute to {fundName}
        </Typography>
      </Grid>
      {!isTablet && !rentalPropertyCalc && <Grid item xs={3} />}
    </>
  )
}

export default SellVsFlockComparisonSection

SellVsFlockComparisonSection.defaultProps = {
  rentalPropertyCalc: false,
  traditionalSaleText: 'net value retained after sale',
}
