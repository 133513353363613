import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Help } from '@mui/icons-material'
import React from 'react'
import { formatIntegerDollars } from '@flock/utils'

type SellCosts = {
  currentHouseValue: number
  salesCosts: number
  otherCosts: number
  stagingCosts: number
  holdingCosts: number
  taxLiability: number
  mortgagePayoff: number
  lostRent: number
  netValue: number
  depreciationRecaptureTaxLiability: number
  capitalGainsTaxLiability: number
  netFees: number
}
type FlockCosts = {
  currentHouseValue: number
  contributingCosts: number
  mortgagePayoff: number
  netValue: number
  netFees: number
}
type SellVsFlockTableProps = {
  sellCosts: SellCosts
  flockCosts: FlockCosts
  rentalPropertyCalc?: boolean
}

const StyledTableCell = styled(TableCell)({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  textAlign: 'right',
})

const TableWrapper = styled('div')({
  overflowX: 'auto',
})

const StyledHelp = styled(Help)({
  fontSize: '1rem',
  cursor: 'pointer',
  marginLeft: '0.25rem',
  marginTop: '-0.15rem',
})

type RowProps = {
  name: string
  data: any[]

  color?: string
  backgroundColor?: string
  bold?: boolean
  tooltip?: string
  rentalPropertyCalc?: boolean
  borderRadius?: boolean
}

const Row = (props: RowProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    name,
    data,
    backgroundColor,
    color,
    bold,
    tooltip,
    rentalPropertyCalc,
    borderRadius,
  } = props
  return (
    <TableRow
      sx={{
        backgroundColor: backgroundColor || 'white',
        borderTop: bold ? '2px solid black' : 'unset',
        borderRadius: borderRadius ? '8px' : 'unset',
      }}
    >
      <StyledTableCell
        sx={{
          fontWeight: bold ? 'bold' : 'normal',
          textAlign: 'left',
          minWidth: isMobile && rentalPropertyCalc ? '0rem' : '12.5rem',
          color,
          paddingLeft: isMobile && rentalPropertyCalc ? '0px' : undefined,
          paddingRight: isMobile && rentalPropertyCalc ? '0px' : undefined,
          fontSize: isMobile && rentalPropertyCalc ? '12px' : '16px',
          width: isMobile && rentalPropertyCalc ? '35%' : undefined,
        }}
      >
        {name}
        {tooltip && (
          <Tooltip title={tooltip} arrow placement="top" enterTouchDelay={0}>
            <StyledHelp sx={{ color: 'gray8.main' }} />
          </Tooltip>
        )}
      </StyledTableCell>
      {data.map((dataPoint) => (
        <StyledTableCell
          sx={{
            fontWeight: bold ? 'bold' : 'normal',
            color,
            fontSize: isMobile && rentalPropertyCalc ? '12px' : '16px',
            paddingLeft: isMobile && rentalPropertyCalc ? '0px' : undefined,
            paddingRight: isMobile && rentalPropertyCalc ? '0px' : undefined,
          }}
        >
          {dataPoint ? formatIntegerDollars(dataPoint) : '-'}
        </StyledTableCell>
      ))}
    </TableRow>
  )
}

Row.defaultProps = {
  backgroundColor: 'white',
  bold: false,
  tooltip: '',
  color: 'black',
  rentalPropertyCalc: false,
  borderRadius: false,
}

const SellVsFlockTable = (props: SellVsFlockTableProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { sellCosts, flockCosts, rentalPropertyCalc } = props
  if (!sellCosts || !flockCosts) {
    return null
  }
  return (
    <TableWrapper>
      <Table sx={{ marginTop: rentalPropertyCalc ? '0rem' : '2rem' }}>
        <TableHead>
          <TableRow>
            {rentalPropertyCalc ? (
              <StyledTableCell
                sx={{
                  fontWeight: rentalPropertyCalc ? '500' : 'bold',
                  textAlign: rentalPropertyCalc ? 'start' : undefined,
                  lineHeight: rentalPropertyCalc ? '18px' : undefined,
                  fontSize: isMobile && rentalPropertyCalc ? '12px' : '16px',
                  paddingLeft:
                    isMobile && rentalPropertyCalc ? '0px' : undefined,
                }}
              >
                Sell vs 721 Fund
              </StyledTableCell>
            ) : (
              <StyledTableCell />
            )}
            <StyledTableCell
              sx={{
                fontWeight: rentalPropertyCalc ? '500' : 'bold',
                padding: rentalPropertyCalc ? '0px' : undefined,
                lineHeight: rentalPropertyCalc ? '18px' : undefined,
                fontSize: isMobile && rentalPropertyCalc ? '12px' : '16px',
              }}
            >
              Traditional Sale
            </StyledTableCell>
            <StyledTableCell
              sx={{
                fontWeight: rentalPropertyCalc ? '500' : 'bold',
                lineHeight: rentalPropertyCalc ? '18px' : undefined,
                padding: rentalPropertyCalc ? '0px' : undefined,
                fontSize: isMobile && rentalPropertyCalc ? '12px' : '16px',
              }}
            >
              Contribute {isMobile && rentalPropertyCalc && <br />} to 721 Fund
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            name="House Value"
            data={[sellCosts.currentHouseValue, flockCosts.currentHouseValue]}
            bold
            rentalPropertyCalc
          />
          <Row
            name="Sale/Contributing Costs"
            data={[sellCosts.salesCosts, flockCosts.contributingCosts]}
            rentalPropertyCalc
          />
          <Row
            name="Outstanding Mortgage"
            data={[sellCosts.mortgagePayoff, flockCosts.mortgagePayoff]}
            rentalPropertyCalc
          />
          <Row
            name="Capital Gains Tax"
            data={[sellCosts.capitalGainsTaxLiability, null]}
            tooltip={
              rentalPropertyCalc
                ? ''
                : 'Capital gains tax rate assumes a 20% federal rate and 5% state rate.'
            }
            rentalPropertyCalc
          />
          <Row
            name={
              rentalPropertyCalc
                ? 'Depreciation Recapture'
                : 'Depreciation Recapture Tax'
            }
            data={[sellCosts.depreciationRecaptureTaxLiability, null]}
            tooltip={
              rentalPropertyCalc
                ? ''
                : 'Depreciation recapture assumes that land accounts for 20% of the purchase price.'
            }
            rentalPropertyCalc
          />
          <Row
            name="Lost Rent"
            data={[sellCosts.lostRent, null]}
            rentalPropertyCalc
          />
          <Row
            name="Staging Costs"
            data={[sellCosts.stagingCosts, null]}
            rentalPropertyCalc
          />
          <Row
            name="Holding Costs"
            data={[sellCosts.holdingCosts, null]}
            rentalPropertyCalc
          />
          <Row
            name="Net Value Retained"
            data={[sellCosts.netValue, flockCosts.netValue]}
            bold
            color={rentalPropertyCalc ? undefined : 'white!important'}
            backgroundColor={
              rentalPropertyCalc ? 'green2.main' : 'primary.main'
            }
            borderRadius
            rentalPropertyCalc
          />
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export default SellVsFlockTable

SellVsFlockTable.defaultProps = {
  rentalPropertyCalc: false,
}
